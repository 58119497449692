import * as React from 'react';

import { useTheme } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const CircularIndeterminate = (props) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress
        variant="indeterminate"
        sx={{
          animationDuration: '0.5s !important',
          width: '25px !important',
          height: '25px !important',
          color: props?.className
            ? theme?.palette?.lightBorder?.main
            : theme?.palette?.common?.white,
        }}
      />
    </Box>
  );
};

export default CircularIndeterminate;
