import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const MyDialog = ({ open, handleClose, title, content, actions, ...props }) => {
  if (props?.type === "homeOwner") {
    return (
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="homeOwner-customized-dialog-title"
          open={open ? true : false}
          {...props}
        >
          <BootstrapDialogTitle
            id="homeOwner-customized-dialog-title"
            onClose={handleClose}
            {...props}
          >
            {props?.inviteType === 'inviteModal' ?
            <div className='backIconModal' style={{fontSize:props?.fontSize}}><ChevronLeftIcon onClick={props?.handleBackModalOption}/>{title}</div> : <div style={{fontSize:props?.fontSize}}>{title}</div>}
          </BootstrapDialogTitle>
          <DialogContent>{content}</DialogContent>
          {actions && <DialogActions>{actions}</DialogActions>}
        </BootstrapDialog>
      </div>
    );
  } else {
    return (
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open ? true : false}
          {...props}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            {...props}
          >
            {title}
          </BootstrapDialogTitle>
          <DialogContent>{content}</DialogContent>
          {actions && <DialogActions>{actions}</DialogActions>}
        </BootstrapDialog>
      </div>
    );
  }
};

export default MyDialog;
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
