export const registerPayload = (data) => {
  return {
    firstName: data?.firstName,
    lastName: data?.lastName,
    email: data?.email,
    password: data?.password,
    phone: data?.phone,
    source: data?.hearAbout,
  };
};

export const changePasswordPayload = (data) => {
  return {
    password: data?.currentPassword,
    newPassword: data?.newPassword,
  };
};

export const updateProfilePayload = (data) => {
  return {
    firstName: data?.firstName,
    lastName: data?.lastName,
    phone: data?.phone,
    source: data?.source,
  };
};

export const allHearAboutUs = (arr) => {
  let obj = {
    id: '0575d794-71bc-417a-b16c-34da0fjhjk678',
    name: 'Choose hear about us',
    status: 'active',
  };
  arr && arr.unshift(obj);
  return arr;
};