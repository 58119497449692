// ---------------------------------------------------- FOR LOCAL FILES ------------------------------------------------------------
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enLanguageText from './assets/locales/en/translation.json';
import esLanguageText from './assets/locales/es/translation.json';
import moment from 'moment/moment';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: enLanguageText,
      },
      es: {
        translation: esLanguageText,
      },
    },
  });

// Function to update the locale
export const updateLocale = (lng) => {
  i18n.changeLanguage(lng);
  moment.locale(lng); // Update moment.js locale
};

// Set up the default language and moment locale
updateLocale(i18n.language);

// Event listener for language changes
i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

export default i18n;

// ------------------------------------------------- FOR locize ----------------------------------------------------------------

// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import enLanguageText from './assets/locales/en/translation.json';
// import esLanguageText from './assets/locales/es/translation.json';

// import Backend from 'i18next-locize-backend';
// import { DateTime } from 'luxon';

// const locizeOptions = {
//   projectId: 'e720956b-811e-442d-a129-e223442c520a',
//   apiKey: 'e9a2fe81-f68f-489b-a92e-9dfe11463ca1', // YOU should not expose your apps API key to production!!!
//   referenceLng: 'en',
// };

// i18n
//   .use(Backend)
//   // detect user language
//   // learn more: https://github.com/i18next/i18next-browser-languageDetector
//   .use(LanguageDetector)
//   // pass the i18n instance to react-i18next.
//   .use(initReactI18next)
//   // init i18next
//   // for all options read: https://www.i18next.com/overview/configuration-options
//   .init({
//     debug: true,
//     fallbackLng: 'en',
//     interpolation: {
//       escapeValue: false, // not needed for react as it escapes by default
//     },
//     supportedLngs: ['en', 'es-419'],
//     ns: 'default',
//     // resources: {
//     //   en:  {
//     //     translation: enLanguageText
//     //   },
//     //   es: {
//     //     translation: esLanguageText
//     //   },
//     // },
//     backend: locizeOptions,
//   });

// // new usage
// i18n.services.formatter.add('DATE_HUGE', (value, lng, options) => {
//   return DateTime.fromJSDate(value)
//     .setLocale(lng)
//     .toLocaleString(DateTime.DATE_HUGE);
// });

// export default i18n;
