import React, { useEffect, useRef, lazy, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import * as authActions from 'redux/auth/actions';
import * as authActions from 'redux/HomeOwner/auth/actions';
import { publicRoutes, privateRoutes } from 'routes';
import PageLoader from 'components/PageLoader';
import Toast from 'components/Toast';
import PublicRoute from 'pages/PublicRoute';
import PrivateRoute from 'pages/PrivateRoute';
// import HomeOwnerPublicRoute from 'pages/HomeOwnerPublicRoute';
// import HomeOwnerPrivateRoute from 'pages/HomeOwnerPrivateRoute';
import ChangePassword from 'pages/Profile/ChangePassword';
import OpenLogoutModal from 'components/ConfirmModal/index';
import * as utils from 'utils';
import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const NotFound = lazy(() => import('pages/NotFound'));
const AcceptProposal = lazy(() => import('pages/AcceptProposal'));
const AppointmentRequest = lazy(() =>
  import('pages/Appointments/AppointmentRequest')
);

function Pages() {
  const initRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  // const auth = useSelector((state) => state.auth);
  const auth = useSelector((state) => state.auth);
  const checkingAuth = auth?.checkingAuth;
  // : auth?.checkingAuth;
  // const isAuth = auth?.isAuth;
  const isAuth = auth?.isAuth;
  const name = utils.getName(auth?.ownerUser);
  //  : utils.getName(auth?.user);
  // const src = auth?.user?.profileImage;
  // const logo = auth?.user?.Organisation?.logo;
  // const companyName = auth?.user?.companyName;

  useEffect(() => {
    if (initRef.current === null) {
      initRef.current = true;
      dispatch(authActions.getUser());
    }
  }, [dispatch, auth?.ownerUser]);

  const handleMenu = (menu) => {
    switch (menu.id) {
      case 1:
        handleProfile();
        break;

      case 2:
        handlePassword();
        break;

      case 3:
        handleLogout();
        break;

      default:
        break;
    }
  };

  const handlePassword = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProfile = () => {
    navigate(`/profile`);
  };

  // const handleLogout = () => {
  //   // if (path.includes('/')) {
  //   //   dispatch(authActions.logout());
  //   // }
  //   // else {
  //   dispatch(authActions.logout());
  //   // }
  // };

  const handleOpenModal = () => {
    setOpenConfirm(true);
  };

  const handleCloseModal = () => {
    setOpenConfirm(false);
  };

  const handleLogout = () => {
    handleOpenModal();
  };

  const handleSubmit = () => {
    dispatch(authActions.logout());
    localStorage.removeItem('projectId');
    handleCloseModal();
  }

  if (checkingAuth) {
    return (
      <div className="container">
        <PageLoader />
      </div>
    );
  }

  const confirmText = {
    title1: 'Are you sure you want to log out?',
    title2: 'This will log you out of the dashboard',
  };

  if (openConfirm) {
    return (
      <OpenLogoutModal
        open={openConfirm}
        handleClose={handleCloseModal}
        titleHead={t('Confirm logout')}
        handleSubmit={handleSubmit}
        confirmMsg={`${t(confirmText?.title1)} ${t(confirmText?.title2)}.`}
        btnMsg={t('Confirm')}
        theme={theme}
        logoutPopUpClass="log_out_pop_Up"
      />
    );
  }

  return (
    <>
      <Toast />
      <ChangePassword open={open} handleClose={handleClose} />

      <Routes>
        {/* {path.includes('/homeOwner') ?
          <>
            {homeOwnerPublicRoutes.map((ele) => {
              return (
                <Route
                  key={ele.id}
                  exact
                  path={ele.path}
                  element={
                    <HomeOwnerPublicRoute isAuth={isAuth} isAuth={isAuth}>{ele.component}</HomeOwnerPublicRoute>
                  }
                />
              );
            })}
          </>
          : */}
        <>
          {publicRoutes.map((ele) => {
            return (
              <Route
                key={ele.id}
                exact
                path={ele.path}
                element={
                  <PublicRoute isAuth={isAuth}>{ele.component}</PublicRoute>
                }
              />
            );
          })}
        </>
        {/* } */}

        {/* {path.includes('/homeOwner') ? <>
          {homeOwnerPrivateRoutes.map((ele) => {
            return (
              <Route
                key={ele.id}
                exact
                path={ele.path}
                element={
                  <HomeOwnerPrivateRoute
                    name={name}
                    initials={auth?.ownerUser}
                    // src={src}
                    isAuth={isAuth}
                    handleMenu={handleMenu}
                  // logo={logo}
                  // companyName={companyName}
                  >
                    {ele.component}
                  </HomeOwnerPrivateRoute>
                }
              />
            );
          })}
        </> : */}
        <>
          {privateRoutes.map((ele) => {
            return (
              <Route
                key={ele.id}
                exact
                path={ele.path}
                element={
                  <PrivateRoute
                    name={name}
                    initials={auth?.ownerUser}
                    // src={src}
                    isAuth={isAuth}
                    handleMenu={handleMenu}
                    handleOpenModal={handleOpenModal}
                  // logo={logo}
                  // companyName={companyName}
                  >
                    {ele.component}
                  </PrivateRoute>
                }
              />
            );
          })}
        </>
        {/* } */}

        {/* Access this route in public/private */}

        {/* Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default Pages;
