import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Spinner from 'components/Spinner';

const MyButton = ({ loading, loadingText, children, disabled, ...props }) => {
  return (
    <Button
      disabled={disabled}
      onClick={!props.loading ? props.onClick : undefined}
      {...props}
    >
      {loading ? (
        <>
          {loadingText === 'Rejecting...' ?
            <Spinner
              className="rejectSpinnerButton"
              size={16}
              thickness={5} /> :
            <Spinner size={16} thickness={5} />
          }
          <Box ml={2}>{loadingText || 'Submitting'}</Box>
        </>
      ) : (
        <>{children}</>
      )}
    </Button>
  );
};

export default MyButton;
