import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';

const PageLoader = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress
        sx={{
          animationDuration: '0.5s !important',
          color: theme?.palette?.lightBorder?.main,
        }}
      />
    </Box>
  );
};

export default PageLoader;
